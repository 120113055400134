<template>
  <div class="d-flex flex-row w-100">
    <b-card
      class="card__discount p-0 mb-0 w-100"
      no-body
    >
      <div :class="discount == 0 ? '' : 'd-flex align-items-center justify-content-between'">
        <div
          v-b-modal.modal-additional-input-discount
          @click="$store.commit('cashier/setIsAddDiscount', false)"
          class="d-flex align-items-center justify-content-between px-2 py-1"
        >
          <div class="d-flex align-items-center">
            <div class="rounded__frame">
              <b-img
                width="17"
                :src="require('@/assets/images/icons/discount.svg')"
              />
            </div>
            <h6 class="text-primary font-weight-bolder mb-0">
              {{ discount > 0 ? `Diskon tambahan ${discountType == 'fixed' ? 'Rp' : ''} ${totalDiscount} ${discountType == 'percent' ? '%' : ''}` : 'Masukkan diskon tambahan % atau Rp' }}
            </h6>
          </div>
          <feather-icon
            v-if="discount == 0"
            class="text-dark font-weight-bolder"
            size="20"
            icon="PlusIcon"
          />
        </div>
        <div
          v-if="discount > 0"
          class="btn-discount px-2 py-1 h-100 align-items-center d-flex"
          @click="resetDiscount"
        >
          <b-spinner
            v-if="isLoading"
            class="mr-1"
            variant="danger"
          />
          <feather-icon
            v-else
            class="text-danger font-weight-bolder"
            size="20"
            icon="XIcon"
          />
        </div>
      </div>
    </b-card>
    <ModalAddtionalInputDiscount />
  </div>
</template>

<script>
import {
  BCard, BImg, VBModal, BSpinner,
} from 'bootstrap-vue'

import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import ModalAddtionalInputDiscount from '@/components/Cashier/Modal/AdditionalInputDiscount.vue'

export default {
  components: {
    BCard,
    BImg,
    BSpinner,
    ModalAddtionalInputDiscount,
  },
  directives: {
    'b-modal': VBModal,
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  computed: {
    totalDiscount() {
      return this.discount > 0 ? Number(this.discount).toLocaleString().replace(/,/g, '.') : 0
    },
  },
  data() {
    return {
      discount: this.$store.state.cart.discountCart,
      discountType: this.$store.state.cart.discountType,
      isLoading: false,
    }
  },
  watch: {
    '$store.state.cart.discountCart': function (value) {
      this.discount = value
    },
    '$store.state.cart.discountType': function (value) {
      this.discountType = value
    },
  },
  methods: {
    resetDiscount() {
      this.isLoading = true
      this.$store.dispatch('cart/removeDiscountFromCartByCart', {
        uuid: this.$store.state.cart.cartUuid,
        payload: '',
      }).then(result => {
        this.$store.commit('cart/setDiscountCart', 0)
        this.$store.commit('cart/setDiscountType', null)
        this.$store.commit('cart/setTotalBuy', result.data.data.items.length)
        this.$store.commit('cart/setTotalPrice', result.data.data.total)
        localStorage.setItem('cash_total_payment', result.data.data.total)
        localStorage.setItem('cartUuid', result.data.data.uuid)

        // this.$store.commit('cart/setDiscountCart', result.data.data.discount_value)
        // this.$store.commit('cart/setDiscountType', result.data.data.discount_type)
        this.$store.commit('cart/setDiscountTotal', result.data.data.discount_total)
        this.$store.commit('cart/setSubtotalCart', result.data.data.subtotal)
        // this.$store.commit('cart/setTotalPrice', result.data.data.total)
        // this.$store.commit('cart/setTotalBuy', result.data.data.items.length)
        this.$store.commit('cart/setCartUuid', result.data.data.uuid)
        this.$store.commit('cart/setResultCartActive', result.data.data)
        this.$store.commit('cashier/setProduct', result.data.data.items.length)
        successNotification(this, 'Success', 'Diskon berhasil di hapus dari cart')
        this.isLoading = false
      }).catch(err => {
        if (err.response.data.meta.messages) {
          errorNotification(this, 'Oops!', err.response.data.meta.messages)
        }
      })
      // this.$store.commit('cashier/setDiscount', 0)
    },
  },
}
</script>
