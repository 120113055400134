<template>
  <b-modal
    id="modal-additional-cost"
    hide-header
    hide-footer
    centered
    no-close-on-backdrop
    title="Vertically Centered"
    ok-only
    ok-title="Accept"
  >
    <div class="p-2 d-flex justify-content-between">
      <div />
      <h1 class="h4 text-dark fw-bold-700 mb-0 size16">
        Tambahan Biaya Tambahan
      </h1>
      <feather-icon
        id="button--close__tambah__biaya"
        class="text-dark fw-bold-700"
        size="20"
        icon="XIcon"
        @click="$bvModal.hide('modal-additional-cost')"
      />
    </div>
    <hr class="mt-0">
    <b-container>
      <b-row class="p-2">
        <b-col cols="12">
          <div
            v-for="(item, index) in formPayload"
            :key="index"
            class="d-flex align-items-center"
          >
            <b-form-group class="custom__form--input mr-1">
              <label for="value-diskon">Nama Biaya</label>
              <input
                :id="`nama-biaya-${index}`"
                v-model="formPayload[index].additional_fee_note"
                type="text"
                class="custom__input pl-2 w-100"
                placeholder="Masukan Nama Biaya"
              >
            </b-form-group>
            <b-form-group class="custom__form--input">
              <label for="value-diskon">Nominal Biaya</label>
              <input
                :id="`nominal-biaya-${index}`"
                type="text"
                :value="formPayload[index].additional_fee_amount"
                class="custom__input pl-2 w-100"
                placeholder="Masukan Nominal Biaya"
                @input="getRupiah(index)"
                @change="getRupiah(index)"
              >
            </b-form-group>
            <b-button
              id="button--hapus__biaya"
              class="p-1 ml-25"
              variant="outline-danger"
              @click="removeItem(index)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
          <b-button
            v-if="formPayload.length < 3"
            id="button--tambah__biaya"
            class="w-100"
            variant="outline-dark"
            @click="repeateAgain"
          >
            + Tambah Biaya Tambahan
          </b-button>
        </b-col>
      </b-row>
    </b-container>
    <div class="button__group">
      <b-button
        id="button--cancel--additional__cost"
        class="button__cancel"
        @click="$bvModal.hide('modal-additional-cost')"
      >
        Batal
      </b-button>
      <b-button
        id="button--save--additional__cost"
        class="button__save"
        :disabled="formPayload && formPayload.length == 0"
        @click="submitAdditionalCost"
      >
        Simpan
      </b-button>
    </div>
  </b-modal>
</template>

<script>
// import { ValidationProvider } from 'vee-validate'
import { mapActions, mapState } from 'vuex'
import {
  BRow, BCol, BModal, BButton, BFormGroup, BContainer,
} from 'bootstrap-vue'
import {
  checkPermission, successNotification, errorNotification, preformatFloat,
} from '@/auth/utils'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BModal,
    BButton,
    BFormGroup,
  },
  props: {
    isStock: {
      type: Boolean,
    },
    formData: {
      type: Object,
    },
    isChange: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState({
      formAdditionalCost: state => state.cart.formAdditionalCost,
    }),
  },
  data() {
    return {
      formPayload: [],
      isLoading: false,
    }
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification, preformatFloat,
    }
  },
  watch: {
    isChange(val) {
      this.formPayload = JSON.parse(localStorage.getItem('additional_cost')) || []
    },
    formAdditionalCost: {
      handler(value) {
        this.formPayload = value
      },
      deep: true,
    },
  },
  created() {
    this.formPayload = JSON.parse(localStorage.getItem('additional_cost'))
  },
  methods: {
    ...mapActions('cart', ['addAddtionalCost']),
    async submitAdditionalCost() {
      try {
        this.isLoading = true
        this.$store.commit('cart/SET_FORM_ADDITIONAL_COST', this.formPayload)
        const form = this.preparePayload()
        const response = await this.addAddtionalCost({
          uuid: this.$store.state.cart.cartUuid,
          payload: form,
        })

        if (response) {
          successNotification(this, 'Success', 'Biaya tambahan berhasil ditambahkan.')
          this.isLoading = false
          this.$bvModal.hide('modal-additional-cost')
          const item = response.data.data
          this.$store.commit('cart/SET_TOTAL_ADDITIONAL_COST', item.additional_fee_total)
          this.$store.commit('cart/setDiscountCart', item.discount_value)
          this.$store.commit('cart/setDiscountType', item.discount_type)
          this.$store.commit('cart/setDiscountTotal', item.discount_total)
          this.$store.commit('cart/setSubtotalCart', item.subtotal)
          this.$store.commit('cart/setTotalPrice', item.total)
          this.$store.commit('cart/setTotalBuy', item.items.length)
          this.$store.commit('cart/setCartUuid', item.uuid)
          this.$store.commit('cart/setResultCartActive', item)
          this.$store.commit('cashier/setProduct', item.items.length)
          localStorage.setItem('cartUuid', item.uuid)
          localStorage.setItem('cash_total_payment', item.total)
          localStorage.setItem('customer', JSON.stringify(item.customer))
          localStorage.setItem('additional_cost', JSON.stringify(this.formPayload))
        }
      } catch (error) {
        this.isLoading = false
      }
    },
    repeateAgain() {
      this.formPayload.push({
        additional_fee_amount: 0,
        additional_fee_note: '',
      })
    },
    removeItem(index) {
      this.formPayload.splice(index, 1)
    },
    getRupiah(index) {
      const rupiah = document.getElementById(`nominal-biaya-${index}`)
      rupiah.value = this.formatRupiah(rupiah.value.toString(), '')
      this.formPayload[index].additional_fee_amount = rupiah.value
    },
    formatRupiah(angka, prefix) {
      const number_string = angka.replace(/[^,\d]/g, '').toString()
      const split = number_string.split(',')
      const sisa = split[0].length % 3
      let rupiah = split[0].substr(0, sisa)
      const ribuan = split[0].substr(sisa).match(/\d{3}/gi)

      if (ribuan) {
        const separator = sisa ? '.' : ''
        rupiah += separator + ribuan.join('.')
      }

      rupiah = split[1] !== undefined ? `${rupiah},${split[1]}` : rupiah
      return prefix === undefined ? rupiah : (rupiah || '')
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          // eslint-disable-next-line no-restricted-syntax
          for (const keySecond in this.formPayload[key]) {
            if (Object.hasOwnProperty.call(this.formPayload[key], keySecond)) {
              const element = this.formPayload[key][keySecond]
              form.append(`additional_fees[${key}][${keySecond}]`, keySecond == 'additional_fee_note' ? element : parseFloat(preformatFloat(element)))
            }
          }
        }
      }
      return form
    },
  },
}
</script>

<style lang="scss">
@import '/src/assets/scss/variables/_variables.scss';

#modal-additional-cost {
  .modal-dialog {

    .modal-content {
      background: #FCFCFC;
      border-radius: 16px;

      .modal-body {
        padding: 0;
        .form-group {
          label {
            font-size: 14px;
            color: $dark;
            font-weight: 600;
          }
          input {
            background: $light--background;
            border: none;
            border-radius: 8px;
            height: 52px;
            color: $dark;
            font-weight: 600;
          }
        }
        .button__group {
          display: flex;
          width: 100%;

          .button__is--discount {
            background: transparent !important;
            border: none !important;
            color: $light--description !important;

            &.active {
              background: #FFFFFF !important;
              border: none !important;
              color: $dark !important;
            }
          }

          .button__cancel {
            background-color: #FFFFFF !important;
            border-color: #fff !important;
            color: $dark !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 0px 16px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }

          .button__save {
            background-color: #45B6AB !important;
            border-color: #45B6AB !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 16px 0px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }

        }
      }
    }
  }
}
</style>
